import Vue from 'vue'
import { decode } from '@/lib/util'
import '../carousel-swiper/carousel-swiper.vue'

export default el => new Vue({
  name: 'Brand Carousel',
  el,
  data () {
    const sectionSettings = JSON.parse(decode(el.getAttribute('settings-data'))) || {
      items: [],
      title: ''
    }
    return {
      ...sectionSettings
    }
  }
})